import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/Home'
import Usuarios from '@/components/admin/usuarios/Usuarios'
import Clientes from '@/components/admin/clientes/Clientes'
import Notas from '@/components/admin/clientes/Notas'
import Agendamentos from '@/components/admin/clientes/Agendamentos'
import Aniversariantes from '@/components/Aniversariantes'
import Metas from '@/components/admin/usuarios/Metas'
import Financeiro from '@/components/admin/Financeiro'
import ReceitasDespesas from '@/components/admin/ReceitasDespesas'
import Relatorios from '@/components/admin/Relatorios'
import Wa from '@/components/admin/wa/Wa'
import Telemarketing from '@/components/admin/clientes/Telemarketing'
import Esteira from '@/components/admin/contratos/Esteira'
import Formalizacao from '@/components/admin/contratos/Formalizacao'
import Contratos from '@/components/admin/contratos/Contratos'
import Auth from '@/components/auth/Auth'
import Lixeira from '@/components/admin/lixeira/Lixeira'

import { userKey } from '@/global'

Vue.use(VueRouter)

const routes = [{
	name: 'auth',
	path:'/auth',
	component: Auth
},{
	name:'home',
	path:'/',
	component: Home
},
{
	name: 'usuarios',
	path: '/usuarios',
	component: Usuarios,
	meta: { requiresAdmin: true } 
},
{
	name: 'clientes',
	path: '/clientes',
	component: Clientes
},
{
	name: 'financeiro',
	path: '/financeiro',
	component: Financeiro,
	meta: { requiresAdmin: true } 
},
{
	name: 'receitas_despesas',
	path: '/receitas_despesas',
	component: ReceitasDespesas,
	meta: { requiresAdmin: true } 
},
{
	name: 'relatorios',
	path: '/relatorios',
	component: Relatorios,
	meta: { requiresAdmin: true } 
},
{
	name: 'telemarketing',
	path: '/telemarketing',
	component: Telemarketing
},
{
	name: 'esteira',
	path: '/esteira',
	component: Esteira
},
{
	name: 'formalizacao',
	path: '/formalizacao',
	component: Formalizacao
},
{
	name: 'contratos',
	path: '/contratos',
	component: Contratos,
},
{
	name: 'agendamentos',
	path: '/agendamentos',
	component: Agendamentos
},
{
	name: 'aniversariantes',
	path: '/aniversariantes',
	component: Aniversariantes
},
{
	name: 'metas',
	path: '/metas',
	component: Metas,
	meta: { requiresAdmin: true }
},
{
	name: 'wa',
	path: '/wa',
	component: Wa,
	meta: { requiresAdmin: true }
},
{
	name: 'lixeira',
	path: '/lixeira',
	component: Lixeira,
	meta: { requiresAdmin: true }
},
{
	name: 'notas',
	path: '/notas',
	component: Notas
}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	const json = localStorage.getItem(userKey)
	if(to.matched.some(record => record.meta.requiresAdmin)) {
		const user = JSON.parse(json)
		user && user.cargo === 'Administrador' || user.cargo === 'Financeiro' ? next() : next({ path: '/'})
	} else {
		next()
	}
})
export default router