import Vue from 'vue'

export const userKey = '__myapp_user'
// export const baseApiUrl = 'https://saeso.app.br:5000'
export const baseApiUrl = 'https://facilita.saeso.app.br:5000'

export function showError(e) {
    if(e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg : e.response.data })
    }else if(typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg : e })
    }else {
        Vue.toasted.global.defaultError()
    }
}
export function alertMessage(e) {
    if(typeof e === 'string') {
        Vue.toasted.global.alertMessage({ msg : e })
   } else {
        Vue.toasted.global.alertMessage()
    }
}

export default { baseApiUrl, showError, userKey }