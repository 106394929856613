<template>
	<v-container fluid>
		<PageTitle icon="mdi mdi-file-document" main="Contratos"/>
		<v-card>
			<v-card-title>
				<v-row>
					<v-col md="4">
						<v-select v-model="contrato.selected" label="Situação do Contrato" @change="reset" :items="options" dense></v-select>
					</v-col>
					<v-col md="4">
						<v-menu
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						:return-value.sync="dates"
						transition="scale-transition"
						offset-y
						min-width="auto"
						>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="dateRangeText"
							label="Data Inicio ~ Data Fim"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
							dense></v-text-field>
						</template>
						<v-date-picker
						v-model="contrato.dates"
						no-title
						scrollable
						range
						locale="pt-br"
						>
						<v-spacer></v-spacer>
						<v-btn
						text
						color="primary"
						@click="$refs.menu.save((contrato.dates = []), reset())"
						>
						Cancel
					</v-btn>
					<v-btn text color="primary" @click="$refs.menu.save(contrato.dates)">
						OK
					</v-btn>
				</v-date-picker>
			</v-menu>
		</v-col>
		<v-col>
			<v-btn small outlined color="primary" @click="loadContratos(contrato)" :disabled="!contrato.selected || !dates[0]">ok</v-btn>
		</v-col>
		<v-col md="8">
			<v-flex row>
				<v-checkbox
				v-model="searchType"
				label="Busca Específica"
				color="red"
				hide-details
				></v-checkbox>
				<v-col v-if="searchType">	
					<v-text-field type="number" v-model="contrato.searchTerm" label="Nª Contrato" autofocus="autofocus" dense></v-text-field>
				</v-col>
				<v-col v-if="searchType" md="3">
					<v-btn x-small color="mr-1 info" @click="searchFilter">buscar</v-btn>
					<v-btn x-small color="grey" @click="reset">limpar</v-btn>
				</v-col>
			</v-flex>
		</v-col>
	</v-row>
	<v-col md="6">	
		<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
	</v-col>
	<v-col md="2" sm="12" v-show="selected.length > 1">
		<v-fab-transition>
			<v-btn small color="error" dark fixed center class="elevation-5" @click="loadRemove(selected)" v-show="contrato.selected == 'CANCELADO' || contrato.selected == 'RECUSADO'">
				<v-icon>mdi-delete</v-icon>
				excluir seleção 
			</v-btn>
		</v-fab-transition>
	</v-col>
</v-card-title>
<v-card-text>
	<v-data-table :items="contratos" :headers="table" :loading="loading" loading-text="carregando..." :search="busca" show-select item-key=id_contrato v-model="selected" dense>
		<template v-slot:item.numero_contrato="{ item }">
			<v-flex>{{ item.numero_contrato }}</v-flex>
			<small class="blue-grey--text">{{ item.status }}</small>
		</template>
		<template v-slot:item.banco_contrato="{ item }">
			<v-flex>{{ item.banco_contrato }}</v-flex>
			<small class="blue-grey--text">{{ item.tabela_contrato }}</small>
		</template>
		<template v-slot:item.nome_cliente="{ item }">
			<v-flex>{{ item.nome_cliente }}</v-flex>
			<small class="blue-grey--text">cpf: {{ item.cpf_cliente }}</small>
		</template>
		<template v-slot:item.valor_contrato="{ item }">
			<v-flex>{{ formatoMoeda(item.valor_contrato) }}</v-flex>
		</template>
		<template v-slot:item.data_contrato="{ item }">
			<v-flex>{{ formatoData(item.data_contrato) }}</v-flex>
			<small class="blue-grey--text">{{ formatoData(item.data_finalizado) }}</small>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn fab small icon v-show="contrato.selected == 'CANCELADO' || contrato.selected == 'RECUSADO'">
				<v-icon @click="loadRemove(item)" small color="error">mdi-delete</v-icon>
			</v-btn>						
		</template>
	</v-data-table>
</v-card-text>
</v-card>
<v-dialog v-model="dialog" max-width="400">
	<v-card>
		<v-card-title> {{ titleForm }} </v-card-title>
		<v-card-actions>
			<v-btn color="primary" @click="remove()">OK</v-btn>
			<v-btn color="grey" @click="dialog = false">Cancelar</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</v-container>
</template>

<script>
	import { mapState } from 'vuex'
	import moment from 'moment'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	export default {

		name: 'Contratos',
		components: { PageTitle },
		data() {
			return {
				searchType:false,
				selected:[],
				dates:[],
				menu:false,
				titleForm:'',
				dialog:false,
				busca:'',
				loading:false,
				contratos:[],
				contrato:{},
				options:[
					{text:'Em Análise', value:'EM ANALISE'},
					{text:'Pag. Enviado', value:'PAG ENVIADO'},
					{text:'Averbação', value:'AVERBACAO'},
					{text:'Finalizado', value:'FINALIZADO'},
					{text:'Recusado', value:'RECUSADO'},
					{text:'Cancelado', value:'CANCELADO'}
					],
				table:[
					{text:'Contrato', value:'numero_contrato'},
					{text:'Banco', value:'banco_contrato'},
					{text:'Valor', value:'valor_contrato'},
					{text:'Cliente', value:'nome_cliente'},
					{text:'Consultor', value:'nome'},
					{text:'Data Contrato', value:'data_contrato'},
					{text:'Informacoes', value:'informacoes'},
					{text:'Ações', value:'actions'},
					]
			};
		},
		methods:{
			loadContratos(item) {
				this.loading = true

				if(item.dates){
					var dti='', dtf=''
					dti = moment(item.dates[0],"YYYY-MM-DD").format('YYYY-MM-DD')
					dtf = moment(item.dates[1],"YYYY-MM-DD").format('YYYY-MM-DD')
					const params = {
						dataIni:dti,
						dataFin:dtf
					}
					axios.get(`${baseApiUrl}/searchContratos/${item.selected}?dates=${JSON.stringify(params)}`)
					.then(res => {
						if(this.user.cargo === 'Consultor'){
							res.data.map(c => {
								if(c.fk_usuario === this.user.id) {
									this.contratos.push(c)
								}
							})
							this.loading = false
						}else{
							this.contratos = res.data
							this.loading = false
						}
						item = null
					})
					.catch(showError)
				}
			},
			loadRemove(item) {
				this.dialog = true
				if(item.length > 1) {
					this.titleForm = 'Deseja Excluir a seleção ?'
					this.contrato.selecao = item
				}else{
					this.contrato.id_contrato = item.id_contrato
					this.titleForm =  `Deseja Excluir o Contrato ${item.numero_contrato} ?`
				}
			},
			remove() {
				let ids = []
				if(this.contrato.selecao) {
					this.contrato.selecao.map(id => {
						ids.push({id:id.id_contrato})
					})
					axios.delete(`${baseApiUrl}/contratos`, {data: {ids}})
					.then(() => {
						this.$toasted.global.defaultSuccess()
						this.loadContratos(this.contrato)
						this.dialog = false
					})
				} else {
					axios.delete(`${baseApiUrl}/contratos/${ids}`)
					.then(() => {
						this.$toasted.global.defaultSuccess()
						this.loadContratos(this.contrato)
						this.dialog = false
					})

				}

			},
			reset() {
				this.contratos = []
				this.dates = []
				this.searchType = false
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			searchFilter() {
				const searchTerm = this.contrato.searchTerm
				axios.get(`${baseApiUrl}/searchContratosFilter/${searchTerm}`)
				.then(res => {
					if(this.user.cargo === 'Consultor'){
						res.data.map(c => {
							if(c.fk_usuario === this.user.id) {
								this.contratos.push(c)
							}
						})
						this.loading = false
					}else{
						this.contratos = res.data
						this.loading = false
					}
				})
				.catch(showError)	
			}
		},
		computed: {
			...mapState(['user']),
			dateRangeText () {
				return this.dates.join(' ~ ')
			},
		}
	};
</script>

<style lang="css" scoped>
</style>
